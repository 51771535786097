import React from 'react';
import './MedicalRecovery.scss';
import recoveryImage from '../../assets/servicePage/recovery.png';

const MedicalRecovery = () => {
    return (
        <section className='orthopedic-section section-common section-bg'>
            <div className="container">
                {/* Secțiunea Recuperare Medicală */}
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="orthopedic-text" data-aos="fade-up" data-aos-duration="2000">
                            <h2>Recuperare medicală</h2>
                            <p>
                                Oferim servicii de recuperare medicală de calitate. Recuperarea medicală este specialitatea esențială pentru persoanele care suferă de diverse probleme de sănătate sau accidente. Aceasta poate fi realizată prin intermediul anumitor tehnici, cum ar fi fizioterapia, kinetoterapia, terapia logopedică sau alte forme de terapie și reabilitare.
                            </p>
                            <ul className="facilities">
                                <li> Personal dedicat</li>
                                <li>️ Planuri personalizate</li>
                                <li>️ Proceduri individuale</li>
                                <li>️ Program extins până la ora 21</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="orthopedic-img" data-aos="fade-up" data-aos-duration="2000">
                            <img src={recoveryImage} alt="Recuperare Medicală" />
                        </div>
                    </div>
                </div>

                {/* Secțiunea Servicii Recuperare Medicală */}
                <div className="facilities-section" data-aos="fade-up" data-aos-duration="2000">
                    <h3>Servicii Recuperare medicală</h3>
                    {/* Fizioterapie */}
                    <div className="service-category">
                        <h4>Fizioterapie</h4>
                        <ul>
                            <li>️ Curenți</li>
                            <li>️ Unde scurte</li>
                            <li>️ Ultrasunete</li>
                            <li>️ Laserterapie</li>
                            <li>️ Tens</li>
                            <li>️ Parafină</li>
                            <li>️ Shockwave</li>
                        </ul>
                    </div>
                    {/* Kinetoterapie */}
                    <div className="service-category">
                        <h4>Kinetoterapie</h4>
                        <ul>
                            <li>️ Kinetoterapie individuală</li>
                            <li>️ Kinetoterapie de grup</li>
                            <li>️ Benzi Kinetoterapie</li>
                        </ul>
                    </div>
                    {/* Masaj */}
                    <div className="service-category">
                        <h4>Masaj</h4>
                        <ul>
                            <li>️ Masaj Drenaj Limfatic</li>
                            <li>️ Masaj Anticelulitic</li>
                            <li>️ Masaj Somatic</li>
                            <li>️ Masaj Facial</li>
                            <li>️ Masaj Capilar</li>
                            <li>️ Masaj de Relaxare</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MedicalRecovery;
