import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../sections/Footer/Footer';
import ServiceBanner from '../sections/ServiceBanner/ServiceBanner';
import Appointment from '../sections/Appointment/Appointment';
import Ortopedie from "../sections/Servicii/Ortopedie";
import Orthopedic from "../sections/Servicii/Orthopedic";
import MedicalRecovery from "../sections/Servicii/MedicalRecovery";

const Services = () => {
    return (
        <>
            <Navbar />
            <ServiceBanner />
            {/*<Ortopedie />*/}
            <Orthopedic />
            <MedicalRecovery />
            <Appointment />
            <Footer />
        </>
    );
};

export default Services;