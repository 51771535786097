import React from 'react';
import './Team.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import imgOne from '../../assets/about/team/profile2.png';
import imgTwo from '../../assets/about/team/profile2.png';
import imgThree from '../../assets/about/team/profile2.png';
import imgFour from '../../assets/about/team/profile2.png';

const Team = () => {
    const teams = {
        'Ortopedie': [
            {
                'img': imgTwo,
                'name': 'Dr. Suchoveschi Dan',
                'info': 'Medic Ortopedie și Traumatologie<br>NCN-chirurgie spinală'
            },
            {
                'img': imgTwo,
                'name': 'Dr. Stegărescu Sorin',
                'info': 'Medic Ortopedie și Traumatologie<br>Competențe in Osteopatie, Acupunctură, Homeopatie,Urgențe prespitalicești, Medicină hiperbarică'
            }
        ],
        'Recuperare Medicală': [
            {
                'img': imgThree,
                'name': 'Dr. Firan Carmen',
                'info': 'Medic Primar Reabilitare Medicală și Balneologie<br>Doctorat în neuroreabilitare'
            },
            {
                'img': imgTwo,
                'name': 'Dr. Iordache Liviu',
                'info': 'Medic Primar Reabilitare Medicală și Balneologie<br>Competențe in acupunctură'
            }
        ],
        'Medicină Sportivă': [
            {
                'img': imgTwo,
                'name': 'Dr. [Metadata]',
                'info': 'Specialist în medicină sportivă, dedicat optimizării performanțelor fizice ale sportivilor.'
            },
            {
                'img': imgTwo,
                'name': 'Dr. [Metadata]',
                'info': 'Specialist în medicină sportivă, dedicat optimizării performanțelor fizice ale sportivilor.'
            }
        ],
        'Psihologie': [
            {
                'img': imgOne,
                'name': 'Barbu Iulia',
                'info': 'Psihoterapeut<br>Consiliere clinică<br>Logopedie'
            },
            {
                'img': imgTwo,
                'name': 'Barbu Eugen',
                'info': 'Psihologie<br>Psiho-diagnostic și evaluare clinică<br>Consultanță psihologică, asistență, intervenție<br>Consiliere și terapie suportivă<br>Educație și training'
            }
        ],
        'Dermatologie': [
            {
                'img': imgTwo,
                'name': 'Dr. [Metadata]',
                'info': 'Medic dermatolog specializat în tratarea afecțiunilor pielii și prevenirea cancerului de piele.'
            },
            {
                'img': imgTwo,
                'name': 'Dr. [Metadata]',
                'info': 'Medic dermatolog specializat în tratarea afecțiunilor pielii și prevenirea cancerului de piele.'
            }
        ],
        'Nutriție': [
            {
                'img': imgFour,
                'name': 'Bianca Pătrașcu',
                'info': 'Nutriționist specializat în planuri alimentare personalizate pentru un stil de viață sănătos.'
            }
        ]
    };

    return (
        <section className='team-section pt-100' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <SectionTitle
                            subTitle="Întâlnește Echipa Noastră"
                            title="Cunoaște Specialiștii Noștri din Fiecare Departament"
                        />
                    </div>

                    <div className="col-lg-5">
                        <p className='pt-5'>Anastasia Clinic reunește o echipă de specialiști dedicați care acoperă diverse domenii: ortopedie, dermatologie, medicină sportivă, recuperare medicală, nutriție și consiliere psihologică. Fiecare dintre noi are un singur scop - sănătatea ta.</p>
                    </div>
                </div>

                {Object.keys(teams).map((department, index) => (
                    <div key={index} className="department-section">
                        <h2 className="department-title">{department}</h2>
                        <div className="row">
                            {teams[department].map((teamMember, idx) => (
                                <div key={idx} className="col-lg-3 col-sm-6">
                                    <div className="team-card">
                                        <div className="team-img">
                                            <img src={teamMember.img} alt={teamMember.name} />
                                        </div>
                                        <h3>{teamMember.name}</h3>
                                        <p className="team-info"
                                           dangerouslySetInnerHTML={{__html: teamMember.info}}></p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Team;