import React from 'react';
import './Emergency.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import EmergencyImg from '../../assets/emergency.png';
import { Link } from 'react-router-dom';

const Emergency = () => {
    return (
        <section className='emergency-section' data-aos="fade-up" data-aos-duration="2000">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="emergency-img">
                            <img src={EmergencyImg} alt="Emergency" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="emergency-text">
                            <SectionTitle subTitle="Ce ne face unici?" title="Îngrijire completă, de la diagnostic la recuperare" description="Oferim pacienților noștri un parcurs medical complet, de la diagnostic precis și tratamente personalizate, până la monitorizarea atentă pe tot parcursul recuperării. Cu o echipă dedicată și tehnologie avansată, ne asigurăm că fiecare pas al îngrijirii este adaptat nevoilor tale, oferindu-ți nu doar tratament eficient, ci și sprijin continuu pe drumul spre vindecare."/>

                            <div className="theme-btn">
                                <Link to='/programare'>Programează-te</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Emergency;