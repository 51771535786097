import React from 'react';
import './Testimonial.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import imgOne from '../../assets/testimonial/1.png';
import imgTwo from '../../assets/testimonial/2.png';
import imgThree from '../../assets/testimonial/3.png';
import TestimoniCard from '../../components/TestimoniCard/TestimoniCard';
import { AiFillStar } from "react-icons/ai";
import Slider from "react-slick";

const Testimonial = () => {

    const testimonails = [
        {
            'img': imgOne,
            'name': 'Andreea Ilie',
            'description' : '“Am apelat la Clinica Anastasia deoarece locuiesc in sectorul 2 din București și le mulțumesc pe această cale. Mi-au pus un diagnostic corect”',
            'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        },
        {
            'img': imgTwo,
            'name': 'Alexandru Popescu',
            'description' : '“Echipa de profesionisti ,o clinica ca in Occident ,Medici in adevaratul sens al cuvantului .Recomand din toate punctele de vedere.Voi revenii peste 6 luni.”',
            'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        },
        {
            'img': imgThree,
            'name': 'Serban Traian Ionut',
            'description' : '“Am ajuns acolo cu un umar aproape imobil in urma unui accident, după nouă ședințe deja pot să beau cafeaua cu mana dreaptă. Excepțional. Mulțumesc personalului.”',
            'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        }
    ]

    // slider
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };

    return (
        <section className='testimonail-section section-bg section-common pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <SectionTitle 
                        subTitle="Testimoniale"
                        title="Ce spun pacienții noștri despre noi"
                        />
                    </div>
                    <div className="col-lg-6">
                        <p className="pt-5">Misiunea noastră este să oferim servicii medicale de top, iar recenziile pacienților noștri reflectă angajamentul nostru. Iată câteva dintre gândurile lor despre experiențele avute la clinica noastră.</p>
                    </div>
                </div>

                <Slider {...settings} className="testimoni-slider">
                    {
                        testimonails.map(testimonail => <TestimoniCard testimonail={testimonail} />)
                    }
                </Slider>
               
            </div>
        </section>
    );
};

export default Testimonial;