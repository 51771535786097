import React from 'react';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import Navbar from '../../components/Navbar/Navbar';
import './Contactus.scss';
import ContactForm from '../../components/ContactForm/ContactForm';
import Footer from '../../sections/Footer/Footer';
import {AiFillHome} from "react-icons/ai";
import ScrollToTop from "../../components/ScrollToTop";

const Contactus = () => {

    const mapLink = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d45562.48934142542!2d26.089289180719007!3d44.46073312508191!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1ff209ebdb5fd%3A0xd9b44cc22bcf09f3!2sPhoenicia%20Business%20Center!5e0!3m2!1sro!2sro!4v1728917752980!5m2!1sro!2sro'

    return (
        <>
            <section className='section-bg section-common contact-section'>
                <Navbar />
                <SectionTitle 
                    title="Contactează-ne"
                    description="Vă stăm la dispoziție pentru orice întrebare sau nevoie legată de serviciile noastre medicale. Completați formularul și un reprezentant vă va contacta în cel mai scurt timp."
                />
            </section>
            <section className='contact-form-area' data-aos="fade-up" data-aos-duration="2000">
                <ContactForm/>

            </section>

            <section className='section-bg section-common map-section'>
                <div className="col-lg-12 col-md-12">
                    <div className="google-map full-width">
                        <iframe title='map' src={mapLink}></iframe>
                        <div className="location-name">
                            <AiFillHome/>
                            <p>Strada Turturelelor Nr. 11A, Phoenicia Business Center parter</p>
                        </div>
                    </div>
                </div>


            </section>


            <Footer/>
        </>
    );
};

export default Contactus;