import React from 'react';
import './Banner.scss';
import {Link} from 'react-router-dom';
import icon from '../../assets/banner/icons/Calling3.png';
import bannerImg from '../../assets/banner/1.png';
import bannerPattern from '../../assets/banner/pattern_02.png';
import shapeOne from '../../assets/banner/vector_01_2.png';
import shapeTwo from '../../assets/banner/vector_02_2.png';
import shapeThree from '../../assets/banner/vector_03.png';
import shapeFour from '../../assets/banner/pattern.png';

const Banner = () => {

    return (
        <section className='section-bg section-common banner-section'>
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="banner-text" data-aos="fade-up" data-aos-duration="2000">
                                    <h1>Specialiști de top pentru recuperarea și sănătatea dumneavoastră</h1>
                                    <p>Anastasia – Clinica noastră de Recuperare Medicală vă pune la dispoziție personal medical cu experiență pentru specialitățile de Ortopedie, Recuperare Medicală, Medicină Sportivă, Dermatologie, Nutriție si Psihologie.</p>

                                    <div className="banner-bottom">
                                        <div className="theme-btn">
                                            <Link to="/programare">Programează-te</Link>
                                        </div>

                                        <div className="banner-call">
                                            <div className='icon'>
                                                <a href="tel:+40754216004"><img src={icon} alt="icon"/></a>
                                            </div>
                                            <div className='call-text'>

                                            <p>Program: L-V 09-22</p>
                                                <h6><a href="tel:+40754216004"
                                                       style={{ textDecoration: 'none', color: 'inherit' }}>(0040) 754-216-004</a>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="banner-img-area" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
                                    <div className="banner-img">
                                        <img src={bannerImg} alt="banner model" />
                                    </div>

                                    {/* Vector Shapes */}
                                    <div className="shapes">
                                        <img src={shapeOne} alt="shape" />
                                        <img src={shapeTwo} alt="shape" />
                                        <img src={shapeThree} alt="shape" />
                                        <img src={shapeFour} alt="shape" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Banner Pattern Vector*/}
            <img className='banner-pattern' src={bannerPattern} alt="banner pattern" />
        </section>
    );
};

export default Banner;