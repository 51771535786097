// ScrollManager.js
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollManager({ children }) {
    const { pathname } = useLocation();
    // const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        // Disable automatic scroll restoration
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
    }, []);

    useEffect(() => {
        // Hide content during navigation
        // setIsReady(false);

        // Reset scroll position
        window.scrollTo(0, 0);

        // Show content after scroll is reset
        // setIsReady(true);
    }, [pathname]);

    // Optionally use a transition for smoother effect
    // return (
    //     <div style={{ opacity: isReady ? 1 : 0, transition: 'opacity 0.1s linear' }}>
    //         {isReady && children}
    //     </div>
    // );
}

export default ScrollManager;
