import React, { useEffect } from 'react';
import './SingleBlog.scss';
import Navbar from '../../components/Navbar/Navbar';
import { useParams } from 'react-router-dom';
import BlogData from '../../sections/Blogs/BlogData';
import Footer from '../../sections/Footer/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';

const SingleBlog = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    const { url } = useParams();
    const blog = BlogData.find(blog => blog.url === url);

    // Verificăm dacă am găsit blogul specific
    if (!blog) {
        return <p>Articolul nu a fost găsit.</p>;
    }

    const { title, img, category, fullContent } = blog;

    return (
        <>
            <Navbar />
            <section className='section-bg pb-70 single-blog' data-aos="fade-up">
                <div className="container single-blog-container">
                    <main className="single-blog-area">
                        <p className="single-blog-category">{category}</p>
                        <h2 className="single-blog-title">{title}</h2>
                        <img className='single-blog-banner' src={img} alt="blog banner" />

                        {fullContent.map((section, index) => (
                            <div key={index} className="single-blog-section">
                                <h3 className="single-blog-section-heading">{section.heading}</h3>
                                <p className="single-blog-text">{section.text}</p>
                            </div>
                        ))}
                    </main>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default SingleBlog;
