import imgOne from '../../assets/blogs/1.png';
import imgTwo from '../../assets/blogs/2.png';
import imgThree from '../../assets/blogs/3.png';

const BlogData = [
    {
        'img': imgOne,
        'url': 'ce-este-hernia-de-disc',
        'title':'Ce este Hernia de disc?',
        'description':'Hernia de disc este o problemă comună a coloanei vertebrale, care poate provoca dureri intense, amorțeală sau slăbiciune la nivelul membrelor și dificultate în a efectua activități zilnice.',
        'category': 'ORTOPEDIE',
        'fullContent': [
            {
                'heading': 'Simptomele și stabilirea diagnosticului',
                'text': 'Simptomele herniei de disc pot varia în funcție de localizarea acesteia, dar pot include durere la nivelul spatelui, amorțeală sau slăbiciune la nivelul picioarelor sau brațelor, dificultate în mișcare și pierderea echilibrului. Diagnosticul este stabilit prin examen fizic, imagistică prin rezonanță magnetică (IRM) sau tomografie computerizată (CT).'
            },
            {
                'heading': 'Cum prevenim hernia de disc?',
                'text': 'Prevenirea herniei de disc se poate face prin adoptarea unui stil de viață sănătos, menținerea unei posturi corecte și evitarea ridicării obiectelor grele fără a folosi o tehnică corespunzătoare. Exercițiile fizice regulate pentru întărirea musculaturii spatelui sunt, de asemenea, esențiale.'
            },
            {
                'heading': 'Concluzie',
                'text': 'Hernia de disc poate avea un impact major asupra calității vieții, dar prin măsuri adecvate de prevenție și tratament, se pot reduce riscurile și îmbunătăți calitatea vieții pacienților.'
            }
        ]
    },
    {
        'img': imgTwo,
        'url':'importanta-alimentatiei',
        'title':'Importanța nutriției pentru prevenirea problemelor la coloană',
        'description':'Alimentația joacă un rol esențial în menținerea sănătății coloanei vertebrale. O dietă echilibrată, bogată în vitamine și minerale, poate ajuta la menținerea forței și flexibilității coloanei.',
        'category': 'ALIMENTATIE',
        'fullContent': [
            {
                'heading': 'Importanța vitaminelor și mineralelor',
                'text': 'Vitaminele precum vitamina D și mineralele, în special calciul, sunt esențiale pentru sănătatea oaselor și prevenirea problemelor coloanei. O dietă bogată în legume, fructe, lactate și proteine poate ajuta la întărirea structurii vertebrale.'
            },
            {
                'heading': 'Hidratarea și mobilitatea',
                'text': 'Hidratarea adecvată este esențială pentru menținerea elasticității discurilor intervertebrale, iar activitatea fizică regulată ajută la menținerea unei coloane vertebrale sănătoase.'
            },
            {
                'heading': 'Sfaturi pentru alimentația zilnică',
                'text': 'Pentru a menține o coloană vertebrală sănătoasă, este important să includeți alimente bogate în antioxidanți, să limitați consumul de grăsimi saturate și să optați pentru un aport adecvat de fibre. O dietă echilibrată poate ajuta, de asemenea, la menținerea unei greutăți corporale optime, reducând presiunea asupra coloanei vertebrale.'
            }
        ]
    },
    {
        'img': imgThree,
        'url':'importanta-medicinei-sportive',
        'title':'Importanța medicinei sportive pentru sportivi',
        'description':'Medicina sportivă este esențială pentru sportivi, contribuind la prevenirea accidentărilor și la maximizarea performanțelor fizice.',
        'category': 'MEDICINA SPORTIVA',
        'fullContent': [
            {
                'heading': 'Prevenirea accidentărilor',
                'text': 'Medicina sportivă include evaluări periodice și programe de antrenament care ajută la prevenirea accidentărilor prin asigurarea unei pregătiri fizice corespunzătoare. Exercițiile personalizate și monitorizarea constantă a progresului sportiv contribuie la reducerea riscului de accidentări.'
            },
            {
                'heading': 'Evaluarea și recuperarea sportivă',
                'text': 'Prin utilizarea metodelor avansate de recuperare, cum ar fi terapia prin masaj, fizioterapia sau crioterapia, sportivii pot avea o recuperare mai rapidă și eficientă după accidentări. Evaluările periodice ajută la identificarea problemelor potențiale înainte ca acestea să devină critice.'
            },
            {
                'heading': 'Rolul nutriției în performanța sportivă',
                'text': 'Nutriția joacă un rol esențial în performanța sportivă. Un aport adecvat de carbohidrați, proteine și grăsimi sănătoase ajută la menținerea energiei și la refacerea țesuturilor musculare. De asemenea, hidratarea corectă este crucială pentru prevenirea oboselii și menținerea echilibrului electrolitic.'
            }
        ]
    }
];

export default BlogData;
