import React from 'react';
import './Expert.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import { BsFillCheckCircleFill } from "react-icons/bs";
import expertImg from '../../assets/expert.png';

const Expert = () => {
    return (
        <section className='expert-section' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="expert-text">
                            <SectionTitle 
                                subTitle="Despre serviciile Anastasia"
                                title="Vă punem la dispoziție servicii de calitate"
                                description="Îngrijirea și comunicarea centrate pe pacient sunt un element critic al serviciilor medicale de calitate. Un alt element crucial al serviciilor medicale de calitate este acela de a avea profesioniști medicali competenți și cu experiență."
                            />

                            <ul>
                                <li>
                                    <BsFillCheckCircleFill/>
                                    Medici Primari cu experiență
                                </li>
                                <li>
                                    <BsFillCheckCircleFill/>
                                    Echipamente de ultimă generație
                                </li>
                                <li>
                                    <BsFillCheckCircleFill/>
                                    Programe de recuperare personalizate
                                </li>
                                <li>
                                    <BsFillCheckCircleFill/>
                                    Consultanță nutrițională specializată
                                </li>
                                <li>
                                    <BsFillCheckCircleFill/>
                                    Suport și consiliere psihologică
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="expert-img">
                        <img src={expertImg} alt="expert" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Expert;