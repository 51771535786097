import iconOne from '../../assets/features/1.png';
import iconTwo from '../../assets/features/2.png';

const featuresData = [
    {
        'icon': iconOne,
        'title': 'Casa Națională de Asigurări de Sănătate'
    },
    {
        'icon': iconTwo,
        'title': 'SIGNAL IDUNA'
    }
]

export default featuresData;