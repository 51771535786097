import React from 'react';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import './Services.scss';
import ServicesData from './ServiceData';
import Service from '../../components/Service/Service';
import { Link } from 'react-router-dom';
import { BsFillArrowRightCircleFill } from "react-icons/bs";


const Services = () => {
    return (
        <section className='service-section pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-6">
                        <SectionTitle title="Îngrijire medicală personalizată pentru fiecare nevoie" subTitle="Servicii"/>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        <p className='service-title-text'>De la ortopedie la nutriție și psihologie, clinica noastră îți pune la dispoziție specialiști dedicați care colaborează pentru a îți asigura o recuperare completă și un stil de viață sănătos.</p>
                    </div>
                </div>

                <div className="row">
                    {
                        ServicesData.map(singleService => <Service serviceList={singleService}/>)
                    }
                </div>
            </div>

            <div className="services-link text-center">
                <Link to='/singleService'>
                    Vezi toate serviciile
                    <BsFillArrowRightCircleFill/>
                </Link>
            </div>
        </section>
    );
};

export default Services;