import iconOne from '../../assets/service/1.png';
import iconTwo from '../../assets/service/2.png';
import iconThree from '../../assets/service/3.png';
import iconFour from '../../assets/service/4.png';

const ServicesData = [
    {
        'icon': iconOne,
        'title': 'Ortopedie',
        'description': 'Tratăm afecțiunile aparatului locomotor cu specialiști în ortopedie.',
    },
    {
        'icon': iconTwo,
        'title': 'Recuperare Medicală',
        'description': 'Servicii de recuperare medicală pentru a-ți reda mobilitatea și sănătatea.',
    },
    {
        'icon': iconThree,
        'title': 'Nutriție',
        'description': 'Programe nutriționale personalizate pentru un stil de viață sănătos.',
    },
    {
        'icon': iconFour,
        'title': 'Psihologie',
        'description': 'Consiliere psihologică pentru echilibrul mental și emoțional.',
    }
]

export default ServicesData;