import React from 'react';
import logo from '../../assets/logo_anastasia2.png';
import './Footer.scss';
import { FaFacebookF, FaInstagram} from "react-icons/fa";
import { Link } from 'react-router-dom';
import call from '../../assets/footer/calling2.png';
import time from '../../assets/footer/time2.png';
import location from '../../assets/footer/location2.png';

const Footer = () => {

    const footerMenu = [
        {
            'name' : 'Despre noi',
            'link' : '/about'
        },
        {
            'name' : 'Servicii',
            'link' : '/singleservice'
        },
        {
            'name' : 'Blog',
            'link' : '/blogs'
        },
        {
            'name' : 'Contact',
            'link' : '/contact'
        },
        {
            'name' : 'Programare',
            'link' : '/programare'
        }
    ];

    const footerContacts = [
        {
            'title': 'Număr de contact',
            'info': '(0040) 754-216-004',
            'icon': call
        },
        {
            'title': 'Program',
            'info': '08:00 AM - 22:00 PM',
            'icon': time
        },
        {
            'title': 'Adresa Clinicii',
            'info': 'Strada Turturelelor Nr. 11A, București',
            'icon': location
        }
    ]

    return (
        <footer className='pt-100 pb-70'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-5">
                        <div className="footer-logo">
                            <img src={logo} alt="logo"/>
                        </div>

                        <p
                            style={{
                                color: '#ffffff',
                                fontSize: '16px',
                            }}
                        >
                            La Clinica Anastasia, misiunea noastră este să îmbunătățim calitatea vieții pacienților prin
                            tratamente inovatoare și îngrijire personalizată.
                        </p>


                        <div className="social-logo">
                            <p>Social Media</p>
                            <ul>
                                <li><a href="/"><FaFacebookF/></a></li>
                                <li><a href="/"><FaInstagram/></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2">
                        <div className="footer-link">
                            <p>Pagini</p>
                            <ul>
                            {
                                    footerMenu.map(singleMenu => <li><Link to={singleMenu.link}>{singleMenu.name}</Link></li>)
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5">
                        <div className="footer-contact">
                            <p>Contact & Program</p>

                            {
                                footerContacts.map(footerContact => {
                                    if(footerContact.title === "Număr de contact") {
                                        footerContact.info =
                                            <a href={"tel:" + footerContact.info}
                                               style={{
                                                   textDecoration: 'none',
                                                   color: 'inherit'
                                               }}>{footerContact.info}</a>
                                        footerContact.icon = <a href={"tel:" + footerContact.info}><img src={footerContact.icon} alt="call"/></a>
                                    }
                                    else{
                                        footerContact.icon = <img src={footerContact.icon} alt="call"/>
                                    }
                                    return  <div className="contact-list">
                                                <div className="contact-icon">
                                                    {footerContact.icon}
                                                </div>
                                                <div className="contact-text">
                                                    <p>{footerContact.title}</p>
                                                    <h5>{footerContact.info}
                                                    </h5>
                                                </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="copyright-area">
                    <div className='copy-text'>
                        <p
                            style={{
                                color: '#ffffff',
                                fontSize: '16px',
                            }}
                        >&copy; Clinica Anastasia. All Right Reserved</p>
                    </div>  
                    <div className='copy-links'>
                        <ul>
                            <li><Link to='/'>Termeni și condiții</Link></li>
                            <li><Link to='/'>Politica de confidențialitate</Link></li>
                        </ul>
                    </div>                          
                </div>
            </div>
        </footer>
    );
};

export default Footer;