import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
        <ScrollToTop/>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// In your main entry file (e.g., index.js)

if (process.env.NODE_ENV === 'development') {
    const suppressedWarnings = ['Each child in a list should have a unique "key" prop'];

    const originalConsoleError = console.error;
    console.error = (message, ...args) => {
        if (!suppressedWarnings.some((warning) => message.includes(warning))) {
            originalConsoleError(message, ...args);
        }
    };
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
