import React from 'react';
import './Orthopedic.scss';
import orthopedicImage from '../../assets/servicePage/ortopedie.png';

const Orthopedic = () => {
    return (
        <section className='orthopedic-section section-common section-bg'>
            <div className="container">
                {/* Secțiunea Despre Ortopedie */}
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="orthopedic-text" data-aos="fade-up" data-aos-duration="2000">
                            <h2>Despre Ortopedie</h2>
                            <p>
                                Serviciile de ortopedie oferă tratamente pentru tulburări musculo-scheletice, inclusiv oase, articulații, ligamente, tendoane și mușchi. Aceste servicii variază de la diagnostic la intervenții chirurgicale și reabilitare. Îngrijirea ortopedică poate ajuta cu artrita, fracturi, dureri de spate, leziuni sportive și tulburări congenitale.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="orthopedic-img" data-aos="fade-up" data-aos-duration="2000">
                            <img src={orthopedicImage} alt="Ortopedie" />
                        </div>
                    </div>
                </div>

                {/* Secțiunea Facilități */}
                <div className="facilities-section" data-aos="fade-up" data-aos-duration="2000">
                    <h3>Facilități</h3>
                    <ul>
                        <li> Personal dedicat</li>
                        <li> Tratamente ambulatoriu</li>
                        <li> Infiltrații articulare</li>
                        <li> Program extins până la ora 21</li>
                    </ul>
                </div>

                {/* Secțiunea Servicii */}
                <div className="services-section" data-aos="fade-up" data-aos-duration="2000">
                    <h3>Servicii</h3>
                    <p>
                        Scopul serviciilor ortopedice este de a îmbunătăți mobilitatea, de a reduce durerea și disconfortul, de a restabili funcția normală și de a îmbunătăți calitatea vieții pacientului.
                    </p>
                    <ul>
                        <li>️ Consultație inițială și control</li>
                        <li>️ Interpretare analize</li>
                        <li>️ Referat medical/handicap</li>
                        <li> Toaletă plăgii și pansament</li>
                        <li> Scos imobilizare atelă gipsată</li>
                        <li> Infiltrație cu acid hialuronic</li>
                    </ul>
                </div>

                {/* Secțiunea Infiltrații cu acid hialuronic */}
                <div className="hyaluronic-section" data-aos="fade-up" data-aos-duration="2000">
                    <h3>Infiltrații cu acid hialuronic</h3>
                    <p>
                        Infiltrațiile cu acid hialuronic sunt o soluție non-chirurgicală pentru tratarea durerilor de genunchi și a rigidității articulațiilor, cauzate de osteoartrită și alte afecțiuni. Rezultatul este o reducere semnificativă a durerii, îmbunătățirea mobilității articulației și reducerea necesității utilizării medicamentelor antiinflamatoare.
                    </p>
                    <p>
                        Infiltrațiile cu acid hialuronic sunt considerate o opțiune sigură și eficientă, cu beneficii pe termen lung pentru pacienții cu probleme de genunchi.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Orthopedic;
